<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {


}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
body {
  font-family: 'PingFang SC';
  width: 100%;
  /*overflow-x: hidden;*/
}
@media  (min-width: 1024px) and (max-width: 1600px){
  body {
    zoom: 0.7;
  }
}
</style>
