//使用原生写法解决初始化忽大忽小问题
function remSize() {
  let screenWidth = window.document.documentElement.clientWidth || window.innerWidth;
  if (screenWidth > 750) {
    screenWidth = 750;
  }
  if (screenWidth < 320) {
    screenWidth = 320
  }


  console.log('screenWidth:', screenWidth);
  document.documentElement.style.fontSize = (screenWidth / 7.5) + 'px';
  document.querySelector('body').style.fontSize = 0.3 + 'rem'
}
remSize()
window.onresize = function () {
  remSize()
}


