import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)


export const routes = [
  {
    path: '/',
    name:'index',
    component:()=>import('@/views/index/index')

  },
  {
    path: '/apparel',
    name:'apparel',
    component:()=>import('@/views/apparel/apparel')

  },
  {
    path: '/interaction',
    name:'interaction',
    component:()=>import('@/views/interaction/interaction')

  },
  {
    path: '/robot',
    name:'robot',
    component:()=>import('@/views/robot/robot')
  },
  {
    path: '/relation',
    name:'relation',
    component:()=>import('@/views/relation/relation')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes,

})


if(!localStorage.getItem('skip')) {
  localStorage.setItem('skip', '1')
}

router.beforeEach((to, from, next) =>{
  console.log(to, from);

  if(localStorage.getItem('skip') === '1'){

    let jsSrc =(navigator.language || navigator.browserLanguage).toLowerCase();
    if(jsSrc.indexOf('zh') >= 0){

      if(localStorage.getItem('skip')==='1') {
        window.location.href="/"
        localStorage.setItem('skip', '2')
      }

    }else if(jsSrc.indexOf('en') >= 0){

      if(localStorage.getItem('skip')==='1') {
        window.location.href = "/en"
        localStorage.setItem('skip', '2')
      }
    }
  }else{
    next()
  }
})


export default router
